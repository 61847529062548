import { Outlet, Navigate } from "react-router-dom";
import { AUTH_USER_ACCESS_TOKEN } from "../utils/Constants";
import { validateToken } from "../utils/Helps";
import { useAppSelector } from "../hooks";
import { selectAuthentication } from "../slices/AuthenticationSlice";

export default function PublicRoutes() {
  let isLogged = validateToken(localStorage.getItem(AUTH_USER_ACCESS_TOKEN));
  const authentication = useAppSelector(selectAuthentication);

  return !authentication || !isLogged ? <Outlet /> : <Navigate to="/" />;
}
