import { Outlet, Navigate } from "react-router-dom";
import { AUTH_USER_ACCESS_TOKEN } from "../utils/Constants";
import { validateToken } from "../utils/Helps";
import { useAppSelector } from "../hooks";
import { selectAuthentication } from "../slices/AuthenticationSlice";
import MainLayout from "../layouts/MainLayout";

export default function PrivateRoutes() {
  let accessToken = localStorage.getItem(AUTH_USER_ACCESS_TOKEN);
  const authentication = useAppSelector(selectAuthentication);

  return authentication.isLogged || accessToken ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <Navigate to="/login" />
  );
}
