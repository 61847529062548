import { useCallback, useEffect, useRef, useState } from "react";
import i18next from "../../i18n";
import { Button, Col, Collapse, Row, Space, Spin, Tooltip } from "antd";
import OrganizationChart from "@dabeng/react-orgchart";
import MyNode from "./MyNode";
import "./family-tree.scss";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setSelectedKey } from "../../slices/LayoutSlice";
import {
  setSelectedMemberId,
  clearSelectedMemberId,
  setDataSource,
  Member,
  Tree,
  selectFamilyTree,
} from "../../slices/FamilyTreeSlice";
import NewMemberModal from "../../components/NewMemberModal";
import API, { TREES_ENDPOINT } from "../../apis/API";
import EditMemberModal from "../../components/EditMemberModal";

const Panel = Collapse.Panel;

export default function FamilyTreeDetail() {
  const { treeId } = useParams();
  const dispatch = useAppDispatch();
  const familyTree = useAppSelector(selectFamilyTree);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const [data, setData] = useState<Tree>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const [zoomChart, setZoomChart] = useState(true);
  const orgchart = useRef();

  const getTreeDetail = useCallback(async () => {
    setLoading(true);
    console.log("here");
    const tree = await API.get<Tree>(`${TREES_ENDPOINT}/${treeId}`);
    const root = await API.get<Member>(
      `${TREES_ENDPOINT}/${treeId}/orgchart/members`
    );
    dispatch(setDataSource(root));
    setData(tree);
    setLoading(false);
  }, []);

  useEffect(() => {
    console.log("Re-render");
    dispatch(setSelectedKey("TREES"));
    getTreeDetail();
    const memberId = searchParams.get("memberId");
    if (memberId) {
      dispatch(setSelectedMemberId(memberId));
      setIsOpenEditModal(true);
    }
  }, [getTreeDetail, state]);

  const handleAddNewMember = () => {
    setIsOpenModal(true);
  };

  const handleUpdateTreeMembers = async (member: Member) => {
    setLoading(true);
    const root = await API.get<Member>(
      `${TREES_ENDPOINT}/${treeId}/orgchart/members`
    );
    dispatch(setDataSource(root));
    setIsOpenModal(false);
    setLoading(false);
    setCurrentStep(0);
  };

  const handleOnClickNode = (nodeData) => {
    if (nodeData) {
      console.log(nodeData);
      setZoomChart(false);
      setIsOpenEditModal(true);
    }
  };

  return (
    <>
      <div className="h-100">
        <div>
          <Row className="d-flex justify-content-center align-items-center">
            <Col span={4}>
              <Button
                type="primary"
                className="bg-danger"
                onClick={() => navigate({ pathname: "/trees" })}
              >
                {i18next.t("tree.back")}
              </Button>
            </Col>
            <Col
              span={16}
              className="d-flex justify-content-center align-items-center"
            >
              <h3 className="m-0">
                <b>{`${data?.name}`.toUpperCase()}</b>
              </h3>
            </Col>
            <Col span={4} className="d-flex flex-row justify-content-end">
              <Space>
                <Tooltip title={i18next.t("tree.export_tooltip")}>
                  <Button type="primary">{i18next.t("tree.export")}</Button>
                </Tooltip>
                <Tooltip title={i18next.t("tree.add_member_tooltip")}>
                  <Button type="primary" onClick={handleAddNewMember}>
                    {i18next.t("tree.add_member")}
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col span={5}></Col>
            <Col span={14}></Col>
            <Col span={5}></Col>
          </Row>
        </div>
        {familyTree.ds ? (
          <div style={{ height: "95%" }}>
            {loading ? (
              <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                <Spin />
              </div>
            ) : (
              <OrganizationChart
                ref={orgchart}
                className="custom-org"
                datasource={JSON.parse(JSON.stringify(familyTree.ds))}
                chartClass="myChart"
                NodeTemplate={MyNode}
                pan={true}
                zoom={zoomChart}
                onClickNode={handleOnClickNode}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <NewMemberModal
        treeId={treeId}
        isOpenModal={isOpenModal}
        onCloseModal={() => {
          setIsOpenModal(false);
          setCurrentStep(0);
        }}
        currentStep={currentStep}
        nextStep={() => setCurrentStep(currentStep + 1)}
        prevStep={() => setCurrentStep(currentStep - 1)}
        updateTreeMembers={(m) => handleUpdateTreeMembers(m)}
      />
      {isOpenEditModal && familyTree.selectedMemberId
        ? (console.log("reder modal"),
          (
            <EditMemberModal
              isOpenModal={isOpenEditModal}
              onCloseModal={() => {
                setIsOpenEditModal(false);
                dispatch(clearSelectedMemberId());
                setZoomChart(true);
              }}
              memberId={familyTree.selectedMemberId}
              treeId={treeId}
              updateTreeMembers={(m) => handleUpdateTreeMembers(m)}
            />
          ))
        : ""}
    </>
  );
}
