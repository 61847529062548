import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";

interface SendInvitationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (email: string) => void;
  email?: string;
}

export function SendInvitationModal(props: SendInvitationModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  useEffect(() => {
    setIsOpen(props.isOpen);
    setEmail(props.email || "");
  }, [props.isOpen]);

  const handleOk = async () => {
    await props.onSubmit(email);
    props.onClose();
  };

  const handleCancel = () => {
    props.onClose();
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmail(email);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email.");
    } else {
      setEmailError("");
    }
  };

  return (
    <>
      <div>
        <Modal
          title="Send Invitation"
          open={isOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{ disabled: !!emailError || !email }}
        >
          <Form>
            <Form.Item
              validateStatus={emailError ? "error" : ""}
              help={emailError}
            >
              <Input
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}
