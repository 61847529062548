import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setSelectedKey } from "../../slices/LayoutSlice";
import { Button, Space, Spin, Table, Tooltip, notification } from "antd";
import Column from "antd/es/table/Column";
import i18next from "../../i18n";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Tree } from "../../slices/FamilyTreeSlice";
import API, { SEARCH_TREES_ENDPOINT } from "../../apis/API";
import { NotificationType } from "../../@types/types";
import TreeModal from "../../components/TreeModal";
import Paragraph from "antd/es/typography/Paragraph";
import { selectAuthentication } from "../../slices/AuthenticationSlice";

export default function FamilyTree() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<Tree[]>([]);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [openModal, setOpenModal] = useState(false);
  const [selectedTree, setSelectedTree] = useState<Tree>();
  const authentication = useAppSelector(selectAuthentication);

  const loadTrees = useCallback(async () => {
    try {
      setLoading(true);
      const result = await API.get<any>(SEARCH_TREES_ENDPOINT, {
        sort: "created_date,desc",
        name: searchKey,
      });
      setData(result.content);
      openNotificationWithIcon(
        i18next.t("tree.load_data_success_title"),
        "",
        "success"
      );
      setLoading(false);
    } catch (error) {
      openNotificationWithIcon(
        i18next.t("tree.load_data_error_title"),
        "",
        "error"
      );
      setLoading(false);
      console.log(error);
    }
  }, []);

  useEffect(() => {
    dispatch(setSelectedKey("TREES"));
    loadTrees();
  }, [loadTrees]);

  const openNotificationWithIcon = (
    title: string,
    description: string,
    type: NotificationType
  ) => {
    api[type]({
      message: title,
      description: description,
      placement: "bottomRight",
    });
  };

  const hanleViewDetail = (id) => {
    navigate({
      pathname: `/trees/${id}/members`,
    });
  };

  const submitTreeModal = (tree: Tree) => {
    setOpenModal(false);
    openNotificationWithIcon(
      i18next.t("tree.load_data_success_title"),
      "",
      "success"
    );
    const index = data.findIndex((d) => d.id === tree.id);
    if (index === -1) {
      setData([tree, ...data]);
    } else {
      data[index] = tree;
      setData([...data]);
    }
    setSelectedTree(undefined);
  };

  const openEditTreeModal = (record: any) => {
    setOpenModal(true);
    setSelectedTree(record);
  };

  const handleOnClickPermission = (treeId) => {
    navigate({ pathname: `/trees/${treeId}/permissions` });
  };

  return (
    <>
      {contextHolder}
      <div className="h-100">
        <div className="d-flex flex-row justify-content-end">
          <Tooltip title={i18next.t("tree.add_member_tooltip")}>
            <Button
              className="d-flex justify-content-center align-items-center px-4"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setOpenModal(true)}
            />
          </Tooltip>
        </div>
        {loading ? (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <Spin />
          </div>
        ) : (
          <Table
            dataSource={data}
            className="h-100"
            style={{ overflow: "scroll" }}
            rowKey="id"
          >
            <Column
              title={"Index"}
              key="index"
              dataIndex={"index"}
              width={"10%"}
              render={(_, record, index) => <span>{index + 1}</span>}
            />
            <Column
              title={i18next.t("tree.table.name_lable")}
              key="name"
              dataIndex={"name"}
              width={"30%"}
            />
            <Column
              title={i18next.t("tree.table.summary_lable")}
              key="summary"
              dataIndex={"summary"}
              width={"40%"}
              render={(_, record: any, index) => (
                <div>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      tooltip: (
                        <div>
                          <Tooltip>{record.summary}</Tooltip>
                        </div>
                      ),
                    }}
                  >
                    {record.summary}
                  </Paragraph>
                </div>
              )}
            />
            <Column
              title={i18next.t("tree.table.action_lable")}
              key={"action"}
              width={"20%"}
              render={(_, record: any) => (
                <Space>
                  <Tooltip title={i18next.t("tree.table.view_tooltip")}>
                    <Button
                      className="d-flex justify-content-center align-items-center"
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => hanleViewDetail(record.id)}
                    />
                  </Tooltip>
                  <Tooltip title={i18next.t("tree.table.edit_tooltip")}>
                    <Button
                      className="d-flex justify-content-center align-items-center bg-warning"
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => openEditTreeModal(record)}
                    />
                  </Tooltip>
                  <Tooltip title={i18next.t("tree.table.permission_tooltip")}>
                    <Button
                      className="d-flex justify-content-center align-items-center bg-info"
                      type="primary"
                      icon={<UsergroupAddOutlined />}
                      onClick={() => handleOnClickPermission(record.id)}
                      disabled={record.ownerId !== authentication.userInfo?.id}
                    />
                  </Tooltip>
                  <Tooltip title={i18next.t("tree.table.delete_tooltip")}>
                    <Button
                      className="d-flex justify-content-center align-items-center bg-danger"
                      type="primary"
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Space>
              )}
            />
          </Table>
        )}
      </div>
      {/* Tree Modal */}
      <TreeModal
        tree={selectedTree}
        isOpenModal={openModal}
        onCloseModal={() => {
          setOpenModal(false);
          setSelectedTree(undefined);
        }}
        onSubmitModal={submitTreeModal}
      />
    </>
  );
}
