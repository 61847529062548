import { Button, Form, Input, Modal } from "antd";
import i18next from "../i18n";
import { Tree } from "../slices/FamilyTreeSlice";
import { useEffect, useState } from "react";
import API, { TREES_ENDPOINT } from "../apis/API";

interface TreeModalInputProps {
  isOpenModal: boolean;
  onCloseModal: () => void;
  onSubmitModal: (tree: Tree) => void;
  tree?: Tree;
}

const { TextArea } = Input;

export default function TreeModal(props: TreeModalInputProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      name: props.tree?.name,
      summary: props.tree?.summary,
    });
  });

  const onFinish = async (values: any) => {
    setLoading(true);
    const payload = {
      name: values.name,
      summary: values.summary,
    };
    let tree;
    if (props.tree) {
      tree = await API.put<Tree>(
        `${TREES_ENDPOINT}/${props.tree?.id}`,
        payload
      );
    } else {
      tree = await API.post<Tree>(TREES_ENDPOINT, payload);
    }

    setLoading(false);
    props.onSubmitModal(tree);
  };

  const onFinishFailed = () => {};
  return (
    <>
      <Modal
        title={i18next.t("tree.add_tree_modal_title")}
        open={props.isOpenModal}
        onOk={() => form.submit()}
        onCancel={props.onCloseModal}
        width={600}
        confirmLoading={loading}
      >
        <Form
          name="basic"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          layout="horizontal"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label={i18next.t("tree.table.name_lable")}
            name="name"
            rules={[
              {
                required: true,
                message: `${i18next.t("tree.field_required")}`,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={i18next.t("tree.table.summary_lable")}
            name="summary"
            rules={[
              {
                required: true,
                message: `${i18next.t("tree.field_required")}`,
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={1000}
              style={{ height: 120, resize: "none" }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
