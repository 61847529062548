import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home/Home";
import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import {
  UserInfo,
  setIsLogged,
  setUserInfo,
} from "../slices/AuthenticationSlice";
import {
  ACCESS_TOKEN_REGEX,
  AUTH_USER_ACCESS_TOKEN,
  AUTH_USER_REFRESH_TOKEN,
  REFRESH_TOKEN_REGEX,
} from "../utils/Constants";
import PrivateRoutes from "./PrivateRoutes";
import FamilyTree from "../pages/FamilyTree";
import PublicRoutes from "./PublicRoutes";
import FamilyTreeDetail from "../pages/FamilyTree/FamilyTreeDetail";
import API, { ME_ENDPOINT } from "../apis/API";
import { useAuthenticator } from "@aws-amplify/ui-react";
import FamilyTreeSummary from "../pages/FamilyTree/FamilyTreeSummary";
import { selectLayout } from "../slices/LayoutSlice";
import FamilyTreePermission from "../pages/FamilyTree/FamilyTreePermission";

export default function AppRouter() {
  const dispatch = useAppDispatch();
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const layout = useAppSelector(selectLayout);

  const me = useCallback(async () => {
    let count = 0;
    let accessToken = localStorage.getItem(AUTH_USER_ACCESS_TOKEN);
    let refreshToken = localStorage.getItem(AUTH_USER_REFRESH_TOKEN);

    if (!accessToken || !refreshToken) {
      while (count < 10) {
        let key = localStorage.key(count) || "";

        if (ACCESS_TOKEN_REGEX.test(key)) {
          accessToken = localStorage.getItem(key);
        }

        if (REFRESH_TOKEN_REGEX.test(key)) {
          refreshToken = localStorage.getItem(key);
        }

        count++;
      }
    }

    if (accessToken && refreshToken) {
      try {
        localStorage.clear();
        localStorage.setItem(AUTH_USER_ACCESS_TOKEN, accessToken);
        localStorage.setItem(AUTH_USER_REFRESH_TOKEN, refreshToken);

        const res = await API.get<UserInfo>(ME_ENDPOINT);
        dispatch(setIsLogged(true));
        dispatch(setUserInfo(res));
      } catch (error) {
        dispatch(setIsLogged(false));
      }
    }
  }, []);

  useEffect(() => {
    me();
  }, [me]);

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/trees" element={<FamilyTree />} />
        <Route path="/trees/:treeId" element={<FamilyTreeSummary />} />
        <Route path="/trees/:treeId/members" element={<FamilyTreeDetail />} />
        <Route
          path="/trees/:treeId/permissions"
          element={<FamilyTreePermission />}
        />
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Route>
      <Route element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
      </Route>
    </Routes>
  );
}
