import i18next from "i18next";
import enNs1 from "./locales/en/ns1.json";
import enFallback from "./locales/en/fallback.json";
import viNs1 from "./locales/vi/ns1.json";

export const defaultNS = "ns1";
export const fallbackNS = "fallback";

i18next.init({
  debug: true,
  lng: "en",
  ns: ["ns1"],
  defaultNS,
  fallbackNS,
  resources: {
    en: {
      ns1: enNs1,
      fallback: enFallback,
    },
    vi: {
      ns1: viNs1,
    },
  },
});

export default i18next;
