import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface UserInfo {
  id: number;
  refUserId: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
}
export interface AuthenticationState {
  isLogged: boolean;
  userInfo?: UserInfo;
}
const initialState: AuthenticationState = {
  isLogged: false,
  userInfo: undefined,
};

const hanldeSetIsLogged = (
  state: AuthenticationState,
  action: PayloadAction<boolean>
) => {
  state.isLogged = action.payload;
};

const handleSetUserInfo = (
  state: AuthenticationState,
  action: PayloadAction<UserInfo>
) => {
  state.userInfo = action.payload;
};

export const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    setIsLogged: hanldeSetIsLogged,
    setUserInfo: handleSetUserInfo,
  },
});

export const { setIsLogged, setUserInfo } = AuthenticationSlice.actions;

export const selectAuthentication = (state: RootState) => state.authentication;

export default AuthenticationSlice.reducer;
