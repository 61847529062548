import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Upload,
  UploadFile,
  notification,
} from "antd";
import i18next from "../i18n";
import { AUTH_USER_ACCESS_TOKEN } from "../utils/Constants";
import { useCallback, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import DateInput from "./DateInput";
import { Member } from "../slices/FamilyTreeSlice";
import API, {
  GET_AVAILABLE_PARENTS,
  MEMBER_DETAIL_ENDPOINT,
} from "../apis/API";
import { formatString } from "../utils/Helps";
import { v4 as uuid } from "uuid";
import { RcFile, UploadProps } from "antd/es/upload";
import { NotificationType } from "../@types/types";
import { MemberPostRequest } from "../models/MemberPostRequest";

interface EditMemberModalInputProps {
  isOpenModal: boolean;
  onCloseModal: () => void;
  updateTreeMembers: (member: Member) => void;
  memberId: string | undefined;
  treeId: string | undefined;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default function EditMemberModal(props: EditMemberModalInputProps) {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [currentImageId, setCurrentImageId] = useState<number>();
  const [currentMember, setCurrentMember] = useState<Member>();
  const [relationMember, setCurrentRelationMember] = useState<Member>();
  const [children, setChildren] = useState<Member[]>([]);
  const [availableParents, setAvailableParents] = useState<Member[]>();
  const [relationId, setRelationId] = useState<string>();

  const getMemberDetail = useCallback(async () => {
    const memberId = props.memberId;
    const treeId = props.treeId;
    if (!memberId || !treeId) return;
    const member: Member = await API.get(
      formatString(MEMBER_DETAIL_ENDPOINT, treeId, memberId)
    );
    if (member) {
      const image: UploadFile = {
        uid: uuid(),
        name: member.image?.originalName,
        fileName: member.image?.originalName,
        url: member.image?.fileURL,
        status: "done",
      };

      setFileList([image]);
      setCurrentImageId(member?.image?.id);
      setCurrentMember(member);

      if (
        member.relationId &&
        (member.type === "NONE" || member.type === "MARRIED_WITH")
      ) {
        const relationMember: Member = await API.get(
          formatString(
            MEMBER_DETAIL_ENDPOINT,
            treeId,
            member.relationId.toString()
          )
        );
        setCurrentRelationMember(relationMember);
        setChildren(member.children || []);
        if (
          member.type === "MARRIED_WITH" &&
          relationMember.children &&
          relationMember.children?.length > 0
        ) {
          setChildren(relationMember.children);
        }
      }
    }
  }, []);

  useEffect(() => {
    getMemberDetail();
  }, [getMemberDetail]);

  const handleSubmitModal = () => {
    form.submit();
  };

  const closeModal = () => {
    props.onCloseModal();
  };

  const onFinish = async (values: any) => {
    const memberId = props.memberId;
    const treeId = props.treeId;
    if (!memberId || !treeId || !currentMember) return;

    setLoading(true);
    const payload: MemberPostRequest = {
      type: currentMember.type,
      name: values.name,
      gender: values.gender,
      relationId: values.parents || currentMember.relationId,
      imageId: currentImageId,
      nicknames: values.nicknames,
      phoneNumber: values.phoneNumber,
      email: values.email,
      summary: values.summary,
      dayOfBirth: values.dateOfBirth?.day,
      monthOfBirth: values.dateOfBirth?.month,
      yearOfBirth: values.dateOfBirth?.year,
      dayOfBirthLunar: values.dateOfBirthLunar?.day,
      monthOfBirthLunar: values.dateOfBirthLunar?.month,
      yearOfBirthLunarName: values.dateOfBirthLunar?.year,
      currentAddress: values.currentAddress,
      dayOfDead: values.dateOfDead?.day,
      monthOfDead: values.dateOfDead?.month,
      yearOfDead: values.dateOfDead?.year,
      dayOfDeadLunar: values.dateOfDeadLunar?.day,
      monthOfDeadLunar: values.dateOfDeadLunar?.month,
      yearOfDeadLunarName: values.dateOfDeadLunar?.year,
      burialAddress: values.burialAddress,
    };

    const member: Member = await API.patch(
      formatString(MEMBER_DETAIL_ENDPOINT, treeId, memberId),
      payload
    );
    props.updateTreeMembers(member);
    setLoading(false);
    setReadOnly(true);
    closeModal();
  };
  const onFinishFailed = () => {};

  const openNotificationWithIcon = (
    title: string,
    description: string,
    type: NotificationType
  ) => {
    api[type]({
      message: title,
      description: description,
      placement: "topRight",
    });
  };

  const handleChangeImage: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      console.log("Uploading");
      setFileList(info.fileList);
    }

    if (info.file.status === "error") {
      setFileList([]);
      openNotificationWithIcon(
        i18next.t("tree.load_data_error_title"),
        "",
        "error"
      );
    }

    if (info.file.status === "done") {
      setFileList(info.fileList);
      setCurrentImageId(info.file.response.id);
    }

    if (info.file.status === "removed") {
      // call API remove file
      setFileList([]);
      setCurrentImageId(undefined);
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };
  const handleCancel = () => setPreviewOpen(false);

  const handleOpenNewMember = (memberId) => {
    window.open(
      `${process.env.REACT_APP_BASE_URL}/trees/${currentMember?.treeId}/members?memberId=${memberId}`,
      "_blank"
    );
  };

  const handleClickEdit = async () => {
    setLoading(true);
    const memberId = props.memberId;
    const treeId = props.treeId;
    if (!memberId || !treeId) return;
    // call API to get available parents
    const availableParents: Member[] = await API.get(
      formatString(GET_AVAILABLE_PARENTS, treeId, memberId)
    );
    setAvailableParents(availableParents);
    setReadOnly(false);
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      {currentMember ? (
        <Modal
          title={i18next.t("tree.edit_member_modal_title")}
          open={props.isOpenModal}
          onCancel={closeModal}
          width={1000}
          footer={[
            <Button key="cancel" onClick={closeModal}>
              Cancel
            </Button>,
            readOnly ? (
              <Button
                key="edit"
                type="primary"
                loading={loading}
                onClick={handleClickEdit}
              >
                Edit
              </Button>
            ) : (
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={handleSubmitModal}
              >
                Save
              </Button>
            ),
          ]}
        >
          <Form
            form={form}
            initialValues={{
              ...currentMember,
              dateOfBirth: {
                day: currentMember.dayOfBirth,
                month: currentMember.monthOfBirth,
                year: currentMember.yearOfBirth,
              },
              dateOfBirthLunar: {
                day: currentMember.dayOfBirthLunar,
                month: currentMember.monthOfBirthLunar,
                year: currentMember.yearOfBirthLunarName,
              },
              dateOfDead: {
                day: currentMember.dayOfDead,
                month: currentMember.monthOfDead,
                year: currentMember.yearOfDead,
              },
              dateOfDeadLunar: {
                day: currentMember.dayOfDeadLunar,
                month: currentMember.monthOfDeadLunar,
                year: currentMember.yearOfDeadLunarName,
              },
            }}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            name="edit member"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="w-100 p-5"
            style={{
              overflowY: "scroll",
              maxHeight: 450,
            }}
          >
            <Form.Item
              name={"avatar"}
              className="d-flex justify-content-center align-items-center"
            >
              <Upload
                disabled={readOnly}
                name="file"
                action={`${process.env.REACT_APP_API_BASE_URL}/files/temp/upload?fileType=IMG_TEMP_MEMBER`}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem(
                    AUTH_USER_ACCESS_TOKEN
                  )}`,
                }}
                listType="picture-circle"
                fileList={fileList}
                onChange={handleChangeImage}
                onPreview={handlePreview}
                maxCount={1}
              >
                {fileList.length >= 1 ? null : (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                )}
              </Upload>
              <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </Form.Item>
            <div>
              {relationMember && currentMember.type === "NONE" ? (
                readOnly ? (
                  <Form.Item
                    label={i18next.t("tree.member_form.parents")}
                    name={"parents"}
                  >
                    {[
                      relationMember,
                      ...(relationMember.marriedWiths || []),
                    ].map((m) => (
                      <Button
                        key={m.id}
                        type="link"
                        onClick={() => handleOpenNewMember(m.id)}
                      >
                        {m.name}
                      </Button>
                    ))}
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={i18next.t("tree.member_form.parents")}
                    name={"parents"}
                  >
                    <Select
                      showSearch={true}
                      options={availableParents?.map((m) => ({
                        value: m.id,
                        label: m.name,
                      }))}
                      defaultValue={relationMember.id}
                    />
                  </Form.Item>
                )
              ) : (
                <></>
              )}
              {relationMember && currentMember.type === "MARRIED_WITH" ? (
                <Form.Item
                  label={i18next.t("tree.member_form.married_withs")}
                  name={"married_with"}
                >
                  {[relationMember].map((m) => (
                    <Button
                      key={m.id}
                      type="link"
                      onClick={() => handleOpenNewMember(m.id)}
                    >
                      {m.name}
                    </Button>
                  ))}
                </Form.Item>
              ) : (
                <></>
              )}
              {currentMember?.marriedWiths &&
              currentMember?.marriedWiths.length > 0 ? (
                <Form.Item
                  label={i18next.t("tree.member_form.married_withs")}
                  name={"married_withs"}
                >
                  {currentMember.marriedWiths.map((m) => (
                    <Button
                      key={m.id}
                      type="link"
                      onClick={() => handleOpenNewMember(m.id)}
                    >
                      {m.name}
                    </Button>
                  ))}
                </Form.Item>
              ) : (
                <></>
              )}
              {children && children.length > 0 ? (
                <Form.Item
                  label={i18next.t("tree.member_form.children")}
                  name={"children"}
                >
                  {children.map((m) => (
                    <Button
                      key={m.id}
                      type="link"
                      onClick={() => handleOpenNewMember(m.id)}
                    >
                      {m.name}
                    </Button>
                  ))}
                </Form.Item>
              ) : (
                <></>
              )}
              <Form.Item
                label={i18next.t("tree.member_form.fullname")}
                name={"name"}
                rules={[
                  {
                    required: !readOnly,
                    message: "Please input your fullname",
                  },
                ]}
              >
                <Input readOnly={readOnly} placeholder="Input your fullname" />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.sex")}
                name={"gender"}
                rules={[
                  {
                    required: !readOnly,
                    message: "Please input your gender",
                  },
                ]}
              >
                <Select
                  defaultValue={currentMember.gender}
                  placeholder={i18next.t("tree.member_form.sex")}
                  showSearch={true}
                  disabled={readOnly}
                  options={[
                    {
                      value: "MALE",
                      label: i18next.t("tree.member_form.gender_options.male"),
                    },
                    {
                      value: "FEMALE",
                      label: i18next.t(
                        "tree.member_form.gender_options.female"
                      ),
                    },
                    {
                      value: "OTHER",
                      label: i18next.t("tree.member_form.gender_options.other"),
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.summary")}
                name={"summary"}
              >
                <Input readOnly={readOnly} placeholder="Input your summary" />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.nicknames")}
                name={"nicknames"}
              >
                <Input readOnly={readOnly} placeholder="Input your nicknames" />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.date_of_birth")}
                name={"dateOfBirth"}
                className="mb-0"
              >
                <DateInput
                  disabled={readOnly}
                  day={currentMember.dayOfBirth}
                  month={currentMember.monthOfBirth}
                  name="dateOfBirth"
                />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.date_of_birth_lunar")}
                name={"dateOfBirthLunar"}
                className="mb-0"
              >
                <DateInput
                  disabled={readOnly}
                  day={currentMember.dayOfBirthLunar}
                  month={currentMember.monthOfBirthLunar}
                  name="dateOfBirthLunar"
                />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.email")}
                name={"email"}
              >
                <Input readOnly={readOnly} placeholder="Input your email" />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.phone")}
                name={"phoneNumber"}
              >
                <Input readOnly={readOnly} placeholder="Input your phone" />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.address")}
                name={"currentAddress"}
              >
                <Input readOnly={readOnly} placeholder="Input your address" />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.date_of_dead")}
                name={"dateOfDead"}
                className="mb-0"
              >
                <DateInput
                  name="dateOfDead"
                  disabled={readOnly}
                  day={currentMember.dayOfDead}
                  month={currentMember.monthOfDead}
                />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.date_of_birth_lunar")}
                name={"dateOfDeadLunar"}
                className="mb-0"
              >
                <DateInput
                  name="dateOfDeadLunar"
                  disabled={readOnly}
                  day={currentMember.dayOfDeadLunar}
                  month={currentMember.monthOfDeadLunar}
                />
              </Form.Item>
              <Form.Item
                label={i18next.t("tree.member_form.burial_address")}
                name={"burialAddress"}
              >
                <Input
                  readOnly={readOnly}
                  placeholder="Input your burial address"
                />
              </Form.Item>
            </div>
          </Form>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
}
