import axios from "axios";
import { AUTH_USER_ACCESS_TOKEN } from "./utils/Constants";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Cache-Control": "no-cache",
  },
  withCredentials: false,
});

instance.defaults.headers.post["Content-Type"] =
  "application/json;charset=utf-8";

// Also add/ configure interceptors && all the other cool stuff
instance.interceptors.request.use(
  (config) => {
    if (
      !config.headers.Authorization &&
      !config.url?.includes("/refreshToken")
    ) {
      const accessToken = localStorage.getItem(AUTH_USER_ACCESS_TOKEN);

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
