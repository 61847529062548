import "antd/dist/reset.css";
import "./global.scss";
import AppRouter from "./router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import config from "./amplifyconfiguration.json";
Amplify.configure(config);

function App() {
  return (
    <>
      <Authenticator.Provider>
        <AppRouter />
        <ToastContainer />
      </Authenticator.Provider>
    </>
  );
}

export default App;
