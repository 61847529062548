import { configureStore } from "@reduxjs/toolkit";
import authenticationReducer from "./slices/AuthenticationSlice";
import layoutSlice from "./slices/LayoutSlice";
import familyTreeSlice from "./slices/FamilyTreeSlice";

export const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    layout: layoutSlice,
    familyTree: familyTreeSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
