import axiosConfig from "../axiosConfig";
import { toast } from "react-toastify";
import {
  AUTH_USER_ACCESS_TOKEN,
  AUTH_USER_REFRESH_TOKEN,
} from "../utils/Constants";

class API {
  constructor() {
    axiosConfig.interceptors.response.use(
      this.handleSuccessResponse,
      this.handleErrorResponse
    );
  }

  handleSuccessResponse(response) {
    return response;
  }

  handleErrorResponse = (error) => {
    if (error.code === "ERR_NETWORK") {
      toast.error("ERR NETWORK !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (error.code === "ERR_CANCELED") {
      toast.error("ERR_CANCELED !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (error && error.response) {
      console.log(error.response.status);
      switch (error.response.status) {
        case 401:
          return this.refreshToken()
            .then((rs) => {
              const accessToken = rs.data.accessToken;
              localStorage.setItem(AUTH_USER_ACCESS_TOKEN, accessToken);
              var config = error.config;
              config.headers.Authorization = `Bearer ${accessToken}`;
              return axiosConfig(error.config);
            })
            .catch((err) => {
              console.log("Error when refresh token: ", err);
              localStorage.clear();
              window.location.replace(
                process.env.REACT_APP_BASE_URL || "http://localhost:3000"
              );
            });
          break;
        case 403:
          break;
        case 404:
          break;
        case 400:
          break;
        default:
          console.log("error");
          // show error page
          break;
      }
    }
    return Promise.reject(error);
  };

  refreshToken = () => {
    return axiosConfig.post(REFRESH_TOKEN_ENDPOINT, {
      refreshToken: localStorage.getItem(AUTH_USER_REFRESH_TOKEN),
    });
  };

  async get<Type>(path, params?): Promise<Type> {
    const res = await axiosConfig.get(path, {
      params: params,
    });

    return res.data;
  }

  async put<Type>(path, payload?): Promise<Type> {
    const res = await axiosConfig.request({
      method: "PUT",
      url: path,
      responseType: "json",
      data: payload,
    });

    return res.data;
  }

  async patch<Type>(path, payload): Promise<Type> {
    const res = await axiosConfig.request({
      method: "PATCH",
      url: path,
      responseType: "json",
      data: payload,
    });

    return res.data;
  }

  async post<Type>(path, payload?): Promise<Type> {
    const res = await axiosConfig.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload,
    });

    return res.data;
  }

  async delete<Type>(path, params?): Promise<Type> {
    const res = await axiosConfig.request({
      method: "DELETE",
      url: path,
      responseType: "json",
      params: params,
    });

    return res.data;
  }
}

export default new API();
export const LOGIN_ENDPOINT = "/public/login";
export const ME_ENDPOINT = "/me";
export const TREES_ENDPOINT = "/trees";
export const REFRESH_TOKEN_ENDPOINT = "/public/refreshToken";
export const SEARCH_TREES_ENDPOINT = `${TREES_ENDPOINT}/search`;
export const MEMBER_DETAIL_ENDPOINT = TREES_ENDPOINT + "/{0}/members/{1}";
export const GET_AVAILABLE_PARENTS =
  TREES_ENDPOINT + "/{0}/members/{1}/parents";
export const GET_TREE_PERMISSIONS = TREES_ENDPOINT + "/{0}/permissions";
export const SEND_INVITATION = TREES_ENDPOINT + "/{0}/permissions/invitations";
export const DELETE_PERMISSION = TREES_ENDPOINT + "/{0}/permissions/{1}";
export const RESEND_INVITATION =
  TREES_ENDPOINT + "/{0}/permissions/{1}/invitations";
export const CHECK_PERMISSION = TREES_ENDPOINT + "/{0}/permissions/{1}";
