import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Gender, MemberType } from "../@types/types";

export interface File {
  id: number;
  originalName: string;
  fileURL: string;
  type: string;
}

export interface Member {
  id: number;
  name: string;
  type: MemberType;
  treeId: number;
  relationId: number;
  image: File;
  gender?: Gender;
  marriedWiths?: Member[];
  children?: Member[];
  dayOfBirth: string;
  monthOfBirth: string;
  yearOfBirth?: string;
  dayOfBirthLunar: string;
  monthOfBirthLunar: string;
  yearOfBirthLunarName: string;
  dayOfDead: string;
  monthOfDead: string;
  yearOfDead?: string;
  dayOfDeadLunar: string;
  monthOfDeadLunar: string;
  yearOfDeadLunarName: string;
}

export interface Tree {
  id: number;
  name: string;
  summary?: string;
  createdDate?: string;
  updatedDate?: string;
  root?: Member;
}

export interface AddedMemberModel {
  addedAt?: number;
  children?: Member[];
}

export interface FamilyTreeState {
  ds?: Member;
  currentTree?: Tree;
  trees?: Tree[];
  selectedMemberId?: string;
}
const initialState: FamilyTreeState = {
  ds: undefined,
  currentTree: undefined,
  trees: [],
};

const handleSetDataSource = (
  state: FamilyTreeState,
  action: PayloadAction<Member>
) => {
  state.ds = action.payload;
};

const hanldeRemoveAt = (
  state: FamilyTreeState,
  action: PayloadAction<number>
) => {
  deleteDataSourceAt(state.ds, action.payload);
};

const handleAddAt = (
  state: FamilyTreeState,
  action: PayloadAction<AddedMemberModel>
) => {
  addIntoDataSource(state.ds, action.payload.addedAt, action.payload.children);
};

const deleteDataSourceAt = (datasource?: Member, removedId?: number) => {
  if (!datasource || !removedId) {
    return;
  }

  if (datasource.id === removedId) {
    delete datasource.children;
    return;
  }

  datasource.children?.forEach((ds) => {
    deleteDataSourceAt(ds, removedId);
  });
};

const addIntoDataSource = (
  ds?: Member,
  addedAt?: number,
  children?: Member[]
) => {
  if (!ds || !addedAt || !children) {
    return;
  }

  if (ds.id === addedAt) {
    ds.children = children;
    return;
  }

  ds.children?.forEach((ds) => {
    addIntoDataSource(ds, addedAt, children);
  });
};

const handleSetTrees = (
  state: FamilyTreeState,
  action: PayloadAction<Tree[]>
) => {
  state.trees = action.payload;
};

const handleAddingTree = (
  state: FamilyTreeState,
  action: PayloadAction<Tree>
) => {
  state.trees = [action.payload, ...(state.trees || [])];
};

const handleSetCurrentTree = (
  state: FamilyTreeState,
  action: PayloadAction<Tree>
) => {
  state.currentTree = action.payload;
};

const handleSetSelectedMemberId = (
  state: FamilyTreeState,
  action: PayloadAction<string>
) => {
  state.selectedMemberId = action.payload;
};

const handleClearSelectedMemberId = (state: FamilyTreeState) => {
  state.selectedMemberId = undefined;
};

export const FamilyTreeSlice = createSlice({
  name: "familyTree",
  initialState,
  reducers: {
    setDataSource: handleSetDataSource,
    removeAt: hanldeRemoveAt,
    addAt: handleAddAt,
    setTrees: handleSetTrees,
    addTree: handleAddingTree,
    setCurrentTree: handleSetCurrentTree,
    setSelectedMemberId: handleSetSelectedMemberId,
    clearSelectedMemberId: handleClearSelectedMemberId,
  },
});

export const {
  setSelectedMemberId,
  clearSelectedMemberId,
  setDataSource,
  removeAt,
  addAt,
  setTrees,
  addTree,
  setCurrentTree,
} = FamilyTreeSlice.actions;

export const selectFamilyTree = (state: RootState) => state.familyTree;

export default FamilyTreeSlice.reducer;
