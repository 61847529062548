import {
  Avatar,
  Button,
  Image,
  Input,
  Layout,
  Menu,
  Switch,
  theme,
} from "antd";
import Sider from "antd/es/layout/Sider";
import { ReactNode, useState } from "react";
import {
  SettingOutlined,
  LockOutlined,
  LogoutOutlined,
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { useAppDispatch, useAppSelector } from "../hooks";
import { setIsLogged } from "../slices/AuthenticationSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { selectLayout, setIsChangedLanguage } from "../slices/LayoutSlice";
import { useNavigate } from "react-router-dom";
import SubMenu from "antd/es/menu/SubMenu";
import { useTranslation } from "react-i18next";
import i18next from "../i18n";
import { useAuthenticator } from "@aws-amplify/ui-react";

type MainlayoutProps = {
  children: ReactNode;
};

export default function MainLayout(props: MainlayoutProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const layout = useAppSelector(selectLayout);
  const { t } = useTranslation();
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const logout = () => {
    signOut();
    localStorage.clear();
    dispatch(setIsLogged(false));
    window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
  };

  const onSelectedSider = (selectInfo: any) => {
    console.log(selectInfo.key);
    if (selectInfo.key === "HOME") {
      navigate({
        pathname: "/home",
      });
    } else if (selectInfo.key === "TREES") {
      navigate({
        pathname: "/trees",
      });
    }
  };

  const onChangeLanguage = () => {
    if (!layout.isChangedLanguage) {
      i18next.changeLanguage("vi");
    } else {
      i18next.changeLanguage("en");
    }
    dispatch(setIsChangedLanguage(!layout.isChangedLanguage));
  };

  return (
    <Layout className="h-100">
      <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
        <div className="d-flex flex-row p-3 justify-content-center align-items-center">
          {collapsed ? (
            <Image width={50} src="/logo.png" />
          ) : (
            <>
              <Image width={50} src="/logo.png" />
              <h3 className="mb-0 mx-2">
                <b>MOMA</b>
              </h3>
            </>
          )}
        </div>
        <Menu
          mode="inline"
          selectedKeys={[layout.selectedkey]}
          onSelect={onSelectedSider}
          selectable={true}
          items={[
            {
              key: "HOME",
              icon: <HomeOutlined />,
              label: i18next.t("sider.home_title"),
            },
            {
              key: "TREES",
              icon: <FontAwesomeIcon icon={icon({ name: "sitemap" })} />,
              label: i18next.t("sider.tree_title"),
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{ padding: 0, background: colorBgContainer }}
          className="d-flex flex-row justify-content-start align-items-center"
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <Input
            addonAfter={<SearchOutlined />}
            placeholder={i18next.t("header.search_placeholder")}
            allowClear
            style={{ maxWidth: 500 }}
          />
          <div className="flex-fill d-flex justify-content-end px-4 align-items-center">
            <Switch
              checkedChildren="VI"
              unCheckedChildren="EN"
              checked={layout.isChangedLanguage}
              style={{
                backgroundColor: layout.isChangedLanguage ? "red" : "blue",
              }}
              onChange={onChangeLanguage}
            />
            <Menu
              mode="horizontal"
              selectable={false}
              triggerSubMenuAction="click"
            >
              <SubMenu
                title={
                  <Avatar src={"/logo.png"} className="border" size={40} />
                }
              >
                <Menu.Item key="setting" icon={<SettingOutlined />}>
                  {i18next.t("header.setting")}
                </Menu.Item>
                <Menu.Item key="changePassword" icon={<LockOutlined />}>
                  {i18next.t("header.change_password")}
                </Menu.Item>
                <Menu.Item
                  key="logout"
                  icon={<LogoutOutlined />}
                  onClick={logout}
                >
                  {i18next.t("header.logout")}
                </Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </Header>
        <Content
          className="mb-0 h-100"
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
}
