import { Button, Col, Collapse, Row, Space, Spin, Tooltip, Input } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import i18next from "../../i18n";
import { useCallback, useEffect, useState } from "react";
import { Tree } from "../../slices/FamilyTreeSlice";
import API, { TREES_ENDPOINT } from "../../apis/API";
import { useAppDispatch } from "../../hooks";
import { setSelectedKey } from "../../slices/LayoutSlice";

const Panel = Collapse.Panel;
const { TextArea } = Input;

export default function FamilyTreeSummary() {
  const { treeId } = useParams();
  const navigate = useNavigate();
  const [tree, setTree] = useState<Tree>();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const getTreeDetail = useCallback(async () => {
    setLoading(true);
    const tree = await API.get<Tree>(`${TREES_ENDPOINT}/${treeId}`);
    setTree(tree);
    setLoading(false);
  }, []);

  useEffect(() => {
    dispatch(setSelectedKey("TREES"));
    getTreeDetail();
  }, [getTreeDetail]);

  return (
    <>
      {loading ? (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          <Spin />
        </div>
      ) : (
        <div className="h-100">
          <div>
            <Row className="d-flex justify-content-center align-items-center">
              <Col span={4}>
                <Button
                  type="primary"
                  className="bg-danger"
                  onClick={() => navigate({ pathname: "/trees" })}
                >
                  {i18next.t("tree.back")}
                </Button>
              </Col>
              <Col
                span={16}
                className="d-flex justify-content-center align-items-center"
              >
                <h3 className="m-0">
                  <b>{`${tree?.name}`.toUpperCase()}</b>
                </h3>
              </Col>
              <Col span={4} className="d-flex flex-row justify-content-end">
                <Space>
                  <Tooltip title={i18next.t("tree.summary.edit")}>
                    <Button type="primary">
                      {i18next.t("tree.summary.edit")}
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col span={4}></Col>
              <Col span={16}>
                <TextArea
                  className="mt-3"
                  style={{ resize: "none" }}
                  title={i18next.t("tree.tree_summary")}
                  rows={4}
                  maxLength={6}
                  value={tree?.summary}
                  readOnly={true}
                />
              </Col>
              <Col span={4}></Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
