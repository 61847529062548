import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAppDispatch } from "../../hooks";
import {
  removeAt,
  addAt,
  Member,
  setSelectedMemberId,
  clearSelectedMemberId,
} from "../../slices/FamilyTreeSlice";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar } from "antd";
import { useNavigate } from "react-router-dom";

const propTypes = {
  nodeData: PropTypes.object.isRequired,
};

const MyNode = ({ nodeData }) => {
  const [isHovering, setIsHovering] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [children, setChildren] = useState<any>();
  const [member, setMember] = useState<Member>();

  useEffect(() => {
    setChildren(nodeData.children);
    setMember(nodeData);
  }, []);

  const hanleCollapse = () => {
    if (member) {
      dispatch(removeAt(member.id));
      member.children = [];
      setIsHovering(false);
      dispatch(clearSelectedMemberId());
    }
  };

  const hanldeExpand = () => {
    if (member) {
      dispatch(addAt({ addedAt: nodeData.id, children: children }));
      dispatch(clearSelectedMemberId());
      member.children = children;
      setMember(member);
      setIsHovering(false);
    }
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleOnClick = (memberId) => {
    dispatch(setSelectedMemberId(memberId));
  };

  return (
    <>
      {member ? (
        <div
          style={{ position: "relative" }}
          className="d-flex flex-row border border-primary rounded mx-2 pt-1"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <div className="node-container">
            <div
              className="d-flex justify-content-center align-items-center flex-column"
              onClick={() => handleOnClick(member?.id)}
            >
              <Avatar
                style={{ zIndex: 100 }}
                className="d-flex justify-content-center align-items-center bg-secondary"
                size={70}
                src={nodeData.image?.fileURL}
                icon={nodeData.image?.fileURL ? <></> : <UserOutlined />}
              />
              <div
                style={{
                  minWidth: 200,
                  maxWidth: 200,
                  minHeight: 100,
                  maxHeight: 100,
                  marginTop: -10,
                  paddingTop: 10,
                  color: "#fff",
                }}
                className={
                  nodeData.gender === "FEMALE"
                    ? "bg-danger border rounded d-flex justify-content-center align-items-center flex-column"
                    : "bg-primary border rounded d-flex justify-content-center align-items-center flex-column"
                }
              >
                <b>{nodeData.name.toUpperCase()}</b>
                <span>{`(${
                  nodeData.yearOfBirth ? nodeData.yearOfBirth : "unknown"
                } - ${
                  nodeData.yearOfDead ? nodeData.yearOfDead : "now"
                })`}</span>
              </div>
            </div>
          </div>
          {nodeData.marriedWiths && nodeData.marriedWiths?.length > 0
            ? nodeData.marriedWiths?.map((m) => {
                return (
                  <div
                    key={m.id}
                    className="d-flex justify-content-center align-items-center flex-column node-container"
                    onClick={() => handleOnClick(m?.id)}
                  >
                    <Avatar
                      style={{ zIndex: 100 }}
                      className="d-flex justify-content-center align-items-center bg-secondary"
                      size={70}
                      src={m.image?.fileURL}
                      icon={m.image?.fileURL ? <></> : <UserOutlined />}
                    />
                    <div
                      style={{
                        minWidth: 200,
                        maxWidth: 200,
                        minHeight: 100,
                        maxHeight: 100,
                        marginTop: -10,
                        paddingTop: 10,
                        color: "#fff",
                      }}
                      className={
                        m.gender.toString() === "FEMALE"
                          ? "bg-danger border rounded d-flex justify-content-center align-items-center flex-column"
                          : "bg-primary border rounded d-flex justify-content-center align-items-center flex-column"
                      }
                    >
                      <b>{m.name.toUpperCase()}</b>
                      <span>{`(${m.yearOfBirth ? m.yearOfBirth : "unknown"} - ${
                        m.yearOfDead ? m.yearOfDead : "now"
                      })`}</span>
                    </div>
                  </div>
                );
              })
            : ""}
          {isHovering &&
          member &&
          member.children &&
          member.children?.length > 0 ? (
            <div style={{ position: "absolute", bottom: -8, width: "100%" }}>
              <MinusCircleFilled
                style={{ fontSize: "150%" }}
                onClick={hanleCollapse}
              />
            </div>
          ) : (
            ""
          )}
          {isHovering &&
          member?.children?.length === 0 &&
          children.length > 0 ? (
            <div style={{ position: "absolute", bottom: -8, width: "100%" }}>
              <PlusCircleFilled
                style={{ fontSize: "150%" }}
                onClick={hanldeExpand}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

MyNode.propTypes = propTypes;

export default MyNode;
