import {
  Button,
  Col,
  Row,
  Space,
  Spin,
  Tooltip,
  Table,
  Tag,
  Checkbox,
  Modal,
  message,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import i18next from "../../i18n";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks";
import { setSelectedKey } from "../../slices/LayoutSlice";
import {
  Permission,
  TreePermission,
  TreePermissionStatus,
} from "../../models/TreePermission";
import Column from "antd/es/table/Column";
import { SendInvitationModal } from "../../components/SendInvitationModal";
import API, {
  CHECK_PERMISSION,
  DELETE_PERMISSION,
  GET_TREE_PERMISSIONS,
  RESEND_INVITATION,
  SEND_INVITATION,
} from "../../apis/API";
import { formatString } from "../../utils/Helps";
import moment from "moment";
import { DEFAULT_DATE_TIME_FORMAT } from "../../utils/Constants";
import { RedoOutlined, DeleteOutlined } from "@ant-design/icons";

export default function FamilyTreePermission() {
  const { treeId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>("");
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const permissionList: Permission[] = ["READ", "WRITE", "DELETE"];

  const getTreePermissions = useCallback(async () => {
    setLoading(true);
    const data = await API.get<TreePermission[]>(
      formatString(GET_TREE_PERMISSIONS, treeId || "")
    );
    setData(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    dispatch(setSelectedKey("TREES"));
    getTreePermissions();
  }, [getTreePermissions]);

  const handleResend = async (record: any) => {
    try {
      await API.put(formatString(RESEND_INVITATION, treeId || "", record.id));
      const updatedData = data.map((item) =>
        item.id === record.id ? { ...item, status: "PENDING" } : item
      );
      setData(updatedData);
      message.success("Invitation resent successfully");
    } catch (error) {
      message.error("Failed to resend invitation");
    }
  };

  const handleDelete = async (record: any) => {
    Modal.confirm({
      title: "Are you sure you want to delete this record?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        await API.delete(
          formatString(DELETE_PERMISSION, treeId || "", record.id)
        );
        const newData = data.filter((item) => item.id !== record.id);
        setData(newData);
      },
    });
  };

  const submitSendInvitation = async (email: string) => {
    try {
      await API.post(
        `${formatString(SEND_INVITATION, treeId || "")}?email=${email}`
      );
      await getTreePermissions();
      message.success("Invitation sent successfully");
    } catch (error) {
      message.error("Failed to send invitation");
    }
  };

  const handleCheckPermission = async (checkedValues, record) => {
    try {
      const payload = {
        permissions: checkedValues,
      };
      await API.put(
        formatString(CHECK_PERMISSION, treeId || "", record.id),
        payload
      );
      message.success("Permissions updated successfully");
    } catch (error) {
      message.error("Failed to update permissions");
    }
  };

  return (
    <>
      {loading ? (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center">
          <Spin />
        </div>
      ) : (
        <div className="h-100">
          <div className="h-100">
            <Row className="d-flex justify-content-center align-items-center">
              <Col span={4}>
                <Button
                  type="primary"
                  className="bg-danger"
                  onClick={() => navigate({ pathname: "/trees" })}
                >
                  {i18next.t("tree.back")}
                </Button>
              </Col>
              <Col
                span={16}
                className="d-flex justify-content-center align-items-center"
              >
                <h3 className="m-0">
                  <b>{`${i18next.t("tree.permission.title")}`}</b>
                </h3>
              </Col>
              <Col span={4} className="d-flex flex-row justify-content-end">
                <Space>
                  <Tooltip title={i18next.t("tree.permission.add")}>
                    <Button type="primary" onClick={() => setIsOpenModal(true)}>
                      {i18next.t("tree.permission.add")}
                    </Button>
                  </Tooltip>
                </Space>
              </Col>
            </Row>
            <Row className="h-100 w-100 p-4">
              <Col className="h-100" span={24}>
                <Table
                  dataSource={data}
                  className="h-100"
                  style={{ overflow: "scroll" }}
                  rowKey="id"
                >
                  <Column
                    title={"Index"}
                    key="index"
                    dataIndex={"index"}
                    width={"5%"}
                    render={(_, record, index) => <span>{index + 1}</span>}
                  />
                  <Column
                    title="Full Name"
                    dataIndex="userFullName"
                    key="fullname"
                    width={"10%"}
                  />
                  <Column
                    title="Email"
                    width={"15%"}
                    dataIndex="userEmail"
                    key="email"
                  />
                  <Column
                    title="Status"
                    width={"15%"}
                    dataIndex="status"
                    key="status"
                    render={(status: TreePermissionStatus) =>
                      status === "PENDING" ? (
                        <Tag color="yellow">
                          {i18next
                            .t(`tree.permission.status.pending`)
                            .toUpperCase()}
                        </Tag>
                      ) : status === "ACCEPTED" ? (
                        <Tag color="green">
                          {i18next
                            .t(`tree.permission.status.accepted`)
                            .toUpperCase()}
                        </Tag>
                      ) : (
                        <Tag color="red">
                          {i18next
                            .t(`tree.permission.status.rejected`)
                            .toUpperCase()}
                        </Tag>
                      )
                    }
                  />
                  <Column
                    title="Permissions"
                    width={"30%"}
                    dataIndex="permissions"
                    key="permissions"
                    render={(permissions: string[], record: any) => (
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        defaultValue={permissions}
                        onChange={(checkedValues) =>
                          handleCheckPermission(checkedValues, record)
                        }
                      >
                        {permissionList.map((permission) => (
                          <Checkbox
                            disabled={record.status !== "ACCEPTED"}
                            value={permission}
                            key={permission}
                          >
                            {permission}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    )}
                  />
                  <Column
                    title="Sent At"
                    width={"10%"}
                    dataIndex="createdDate"
                    key="createdDate"
                    render={(date: string) => (
                      <span>
                        {moment(date).format(DEFAULT_DATE_TIME_FORMAT)}
                      </span>
                    )}
                  />
                  <Column
                    title="Actions"
                    width={"15%"}
                    key="actions"
                    render={(text, record) => (
                      <div className="d-flex flex-row">
                        <Space>
                          <Button
                            type="primary"
                            onClick={() => handleResend(record)}
                            icon={<RedoOutlined />}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                          <Button
                            type="primary"
                            danger
                            onClick={() => handleDelete(record)}
                            icon={<DeleteOutlined />}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          />
                        </Space>
                      </div>
                    )}
                  />
                </Table>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <SendInvitationModal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          setSelectedEmail("");
        }}
        onSubmit={(email) => submitSendInvitation(email)}
        email={selectedEmail}
      />
    </>
  );
}
