import { Form, Input, Select } from "antd";
import { useEffect, useState } from "react";

interface DateInputProps {
  name: string;
  day?: string;
  month?: string;
  year?: string;
  disabled?: boolean;
}

export default function DateInput(props: DateInputProps) {
  const [day, setDay] = useState(props.day);
  const [month, setMonth] = useState(props.month);
  const [year, setYear] = useState(props.year);

  const handleOnChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setYear(value);
  };

  return (
    <>
      <Form.Item
        name={[props.name, "day"]}
        style={{
          display: "inline-block",
          width: "calc(30% - 5px)",
        }}
      >
        <Select
          placeholder="Day"
          allowClear
          defaultValue={day}
          onChange={setDay}
          showSearch={true}
          disabled={props.disabled || false}
        >
          {!month || month === "0" ? (
            ""
          ) : (
            <>
              <Select.Option value="1">1</Select.Option>
              <Select.Option value="2">2</Select.Option>
              <Select.Option value="3">3</Select.Option>
              <Select.Option value="4">4</Select.Option>
              <Select.Option value="5">5</Select.Option>
              <Select.Option value="6">6</Select.Option>
              <Select.Option value="7">7</Select.Option>
              <Select.Option value="8">8</Select.Option>
              <Select.Option value="9">9</Select.Option>
              <Select.Option value="10">10</Select.Option>
              <Select.Option value="11">11</Select.Option>
              <Select.Option value="12">12</Select.Option>
              <Select.Option value="13">13</Select.Option>
              <Select.Option value="14">14</Select.Option>
              <Select.Option value="15">15</Select.Option>
              <Select.Option value="16">16</Select.Option>
              <Select.Option value="17">17</Select.Option>
              <Select.Option value="18">18</Select.Option>
              <Select.Option value="19">19</Select.Option>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="21">21</Select.Option>
              <Select.Option value="22">22</Select.Option>
              <Select.Option value="23">23</Select.Option>
              <Select.Option value="24">24</Select.Option>
              <Select.Option value="25">25</Select.Option>
              <Select.Option value="26">26</Select.Option>
              <Select.Option value="27">27</Select.Option>
              <Select.Option value="28">28</Select.Option>
              <Select.Option value="29">29</Select.Option>
            </>
          )}
          {month && ["1", "3", "5", "7", "8", "10", "12"].includes(month) ? (
            <>
              <Select.Option value="30">30</Select.Option>
              <Select.Option value="31">31</Select.Option>
            </>
          ) : month && ["4", "6", "9", "11"].includes(month) ? (
            <>
              <Select.Option value="30">30</Select.Option>
            </>
          ) : (
            ""
          )}
        </Select>
      </Form.Item>
      <Form.Item
        className="mx-3"
        name={[props.name, "month"]}
        style={{
          display: "inline-block",
          width: "calc(30% - 5px)",
        }}
      >
        <Select
          placeholder="Month"
          value={month}
          allowClear
          onChange={setMonth}
          defaultValue={month}
          showSearch={true}
          disabled={props.disabled || false}
        >
          <Select.Option value="1">1</Select.Option>
          <Select.Option value="2">2</Select.Option>
          <Select.Option value="3">3</Select.Option>
          <Select.Option value="4">4</Select.Option>
          <Select.Option value="5">5</Select.Option>
          <Select.Option value="6">6</Select.Option>
          <Select.Option value="7">7</Select.Option>
          <Select.Option value="8">8</Select.Option>
          <Select.Option value="9">9</Select.Option>
          <Select.Option value="10">10</Select.Option>
          <Select.Option value="11">11</Select.Option>
          <Select.Option value="12">12</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        name={[props.name, "year"]}
        style={{
          display: "inline-block",
          width: "calc(30% - 5px)",
        }}
      >
        <Input
          style={{ width: 120 }}
          value={year}
          onChange={handleOnChangeYear}
          placeholder="Year"
          disabled={props.disabled || false}
        />
      </Form.Item>
    </>
  );
}
