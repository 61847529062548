import { useEffect } from "react";
import { useAppDispatch } from "../../hooks";
import { setSelectedKey } from "../../slices/LayoutSlice";
import i18next from "../../i18n";

export default function Home() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedKey("HOME"));
  });
  return <div>{i18next.t("sider.home_title")}</div>;
}
