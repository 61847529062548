import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Steps,
  Upload,
  UploadFile,
  notification,
  theme,
} from "antd";
import i18next from "../i18n";
import { useState } from "react";
import { RcFile, UploadProps } from "antd/es/upload";
import { PlusOutlined } from "@ant-design/icons";
import DateInput from "./DateInput";
import { MemberType, NotificationType } from "../@types/types";
import { Member } from "../slices/FamilyTreeSlice";
import API, { TREES_ENDPOINT } from "../apis/API";
import { AUTH_USER_ACCESS_TOKEN } from "../utils/Constants";
import { MemberPostRequest } from "../models/MemberPostRequest";

const steps = [
  {
    title: i18next.t("tree.add_member_first_step_title"),
    content: "First-content",
  },
  {
    title: i18next.t("tree.add_member_second_step_title"),
    content: "Second-content",
  },
  {
    title: i18next.t("tree.add_member_third_step_title"),
    content: "Last-content",
  },
];

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface NewMemberModalInputProps {
  treeId: string | undefined;
  isOpenModal: boolean;
  onCloseModal: () => void;
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
  updateTreeMembers: (member: Member) => void;
}

export default function NewMemberModal(props: NewMemberModalInputProps) {
  const { token } = theme.useToken();
  const [memberType, setMemberType] = useState<MemberType>("ROOT");
  const [form] = Form.useForm();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Member[]>([]);
  const [relationId, setRelationId] = useState<number>();
  const [loadingNext, setLoadingNext] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [currentImageId, setCurrentImageId] = useState<number>();
  const [api, contextHolder] = notification.useNotification();

  const contentStyle: React.CSSProperties = {
    maxHeight: "500px",
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    padding: "20px",
    paddingRight: "0px !importtant",
    overflow: "hidden",
  };

  const handleSubmitModal = () => {
    console.log("submit modal");
  };

  const selectMemberType = (e: RadioChangeEvent) => {
    setMemberType(e.target.value);
  };

  const onSelectMember = (value: number) => {
    setRelationId(value);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  const filterOption = (input: string, option: any) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onFinish = async (values: any) => {
    setLoadingSubmit(true);
    const payload: MemberPostRequest = {
      type: memberType,
      name: values.name,
      gender: values.gender,
      relationId: relationId,
      imageId: currentImageId,
      nicknames: values.nicknames,
      phoneNumber: values.phoneNumber,
      email: values.email,
      summary: values.summary,
      dayOfBirth: values.dateOfBirth?.day,
      monthOfBirth: values.dateOfBirth?.month,
      yearOfBirth: values.dateOfBirth?.year,
      dayOfBirthLunar: values.dateOfBirthLunar?.day,
      monthOfBirthLunar: values.dateOfBirthLunar?.month,
      yearOfBirthLunarName: values.dateOfBirthLunar?.year,
      currentAddress: values.currentAddress,
      dayOfDead: values.dateOfDead?.day,
      monthOfDead: values.dateOfDead?.month,
      yearOfDead: values.dateOfDead?.year,
      dayOfDeadLunar: values.dateOfDeadLunar?.day,
      monthOfDeadLunar: values.dateOfDeadLunar?.month,
      yearOfDeadLunarName: values.dateOfDeadLunar?.year,
      burialAddress: values.burialAddress,
    };

    const member = await API.post<Member>(
      `${TREES_ENDPOINT}/${props.treeId}/members`,
      payload
    );
    setLoadingSubmit(false);
    resetData();
    props.updateTreeMembers(member);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("failed", errorInfo);
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const openNotificationWithIcon = (
    title: string,
    description: string,
    type: NotificationType
  ) => {
    api[type]({
      message: title,
      description: description,
      placement: "topRight",
    });
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      console.log("Uploading");
      setFileList(info.fileList);
    }

    if (info.file.status === "error") {
      setFileList([]);
      openNotificationWithIcon(
        i18next.t("tree.load_data_error_title"),
        "",
        "error"
      );
    }

    if (info.file.status === "done") {
      setFileList(info.fileList);
      setCurrentImageId(info.file.response.id);
    }

    if (info.file.status === "removed") {
      // call API remove file
      setFileList([]);
      setCurrentImageId(undefined);
    }
  };

  const handleOnNext = async () => {
    setLoadingNext(true);
    const members = await API.get<Member[]>(
      `${TREES_ENDPOINT}/${props.treeId}/members`,
      {
        type: memberType,
      }
    );
    setLoadingNext(false);
    setSelectedMembers(members);
    props.nextStep();
  };

  const resetData = () => {
    setCurrentImageId(undefined);
    setMemberType("ROOT");
    setFileList([]);
    setRelationId(undefined);
    form.resetFields();
  };

  const closeModal = () => {
    resetData();
    props.onCloseModal();
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={i18next.t("tree.add_member_modal_title")}
        open={props.isOpenModal}
        onOk={handleSubmitModal}
        onCancel={closeModal}
        width={1000}
        footer={[
          props.currentStep > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={props.prevStep}>
              {i18next.t("tree.add_member_previous_step")}
            </Button>
          ),
          props.currentStep < steps.length - 1 && (
            <Button type="primary" onClick={handleOnNext} loading={loadingNext}>
              {i18next.t("tree.add_member_next_step")}
            </Button>
          ),
          props.currentStep === steps.length - 1 && (
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
              loading={loadingSubmit}
            >
              {i18next.t("tree.add_member_done_step")}
            </Button>
          ),
        ]}
      >
        <Steps
          current={props.currentStep}
          items={[
            {
              title: i18next.t("tree.add_member_first_step_title"),
            },
            {
              title: i18next.t("tree.add_member_second_step_title"),
            },
            {
              title: i18next.t("tree.add_member_third_step_title"),
            },
          ]}
        />
        <div style={contentStyle}>
          {props.currentStep === 0 ? (
            <>
              <Radio.Group onChange={selectMemberType} value={memberType}>
                <Space direction="vertical">
                  <Radio key="root" value={"ROOT"}>
                    {i18next.t("tree.parents_type_title")}
                  </Radio>
                  <Radio key="child" value={"NONE"}>
                    {i18next.t("tree.child_type_title")}
                  </Radio>
                  <Radio key="married_with" value={"MARRIED_WITH"}>
                    {i18next.t("tree.married_with_type_title")}
                  </Radio>
                </Space>
              </Radio.Group>
            </>
          ) : props.currentStep === 1 ? (
            <>
              <h5>
                {memberType === "ROOT"
                  ? i18next.t("tree.parents_of_title")
                  : memberType === "NONE"
                  ? i18next.t("tree.child_of_title")
                  : i18next.t("tree.married_with_title")}
              </h5>
              <Select
                style={{ minWidth: "300px" }}
                showSearch
                placeholder={i18next.t("tree.select_member_placeholder")}
                optionFilterProp="children"
                onChange={onSelectMember}
                onSearch={onSearch}
                filterOption={filterOption}
                options={selectedMembers.map((m) => {
                  const data = {
                    value: m.id,
                    label: m.name,
                  };

                  return data;
                })}
              />
            </>
          ) : (
            <Form
              form={form}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              layout="horizontal"
              name="add member"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              className=""
              style={{
                overflowY: "scroll",
                maxHeight: 450,
              }}
            >
              <Form.Item
                name={"avatar"}
                className="w-50 d-flex justify-content-center align-items-center"
              >
                <Upload
                  name="file"
                  action={`${process.env.REACT_APP_API_BASE_URL}/files/temp/upload?fileType=IMG_TEMP_MEMBER`}
                  headers={{
                    Authorization: `Bearer ${localStorage.getItem(
                      AUTH_USER_ACCESS_TOKEN
                    )}`,
                  }}
                  listType="picture-circle"
                  fileList={fileList}
                  onChange={handleChange}
                  onPreview={handlePreview}
                  maxCount={1}
                >
                  {fileList.length >= 1 ? null : (
                    <div>
                      <PlusOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
                <Modal
                  open={previewOpen}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </Form.Item>
              <div className="w-50">
                <Form.Item
                  label={
                    memberType === "ROOT"
                      ? i18next.t("tree.parents_of_title")
                      : memberType === "NONE"
                      ? i18next.t("tree.child_of_title")
                      : i18next.t("tree.married_with_title")
                  }
                >
                  <Input
                    placeholder="Input your fullname"
                    value={
                      selectedMembers.find((m) => m.id === relationId)?.name
                    }
                    readOnly
                  />
                </Form.Item>
                <Form.Item
                  label="Fullname"
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your fullname",
                    },
                  ]}
                >
                  <Input placeholder="Input your fullname" />
                </Form.Item>
                <Form.Item
                  label="Gender"
                  name={"gender"}
                  rules={[
                    {
                      required: true,
                      message: "Please input your gender",
                    },
                  ]}
                >
                  <Select
                    placeholder="Male"
                    options={[
                      { value: "MALE", label: "Male" },
                      { value: "FEMALE", label: "Female" },
                      { value: "OTHER", label: "Other" },
                    ]}
                  />
                </Form.Item>
                <Form.Item label="Summary" name={"summary"}>
                  <Input placeholder="Input your summary" />
                </Form.Item>
                <Form.Item label="Nicknames" name={"nicknames"}>
                  <Input placeholder="Input your nicknames" />
                </Form.Item>
                <Form.Item label="Date of birth" className="mb-0">
                  <DateInput name="dateOfBirth" />
                </Form.Item>
                <Form.Item label="Date of birth lunar" className="mb-0">
                  <DateInput name="dateOfBirthLunar" />
                </Form.Item>
                <Form.Item label="Email" name={"email"}>
                  <Input placeholder="Input your email" />
                </Form.Item>
                <Form.Item label="Phone" name={"phoneNumber"}>
                  <Input placeholder="Input your phone" />
                </Form.Item>
                <Form.Item label="Address" name={"currentAddress"}>
                  <Input placeholder="Input your address" />
                </Form.Item>
                <Form.Item label="Date of dead" className="mb-0">
                  <DateInput name="dateOfDead" />
                </Form.Item>
                <Form.Item label="Date of dead lunar" className="mb-0">
                  <DateInput name="dateOfDeadLunar" />
                </Form.Item>
                <Form.Item label="Burial address" name={"burialAddress"}>
                  <Input placeholder="Input your burial address" />
                </Form.Item>
              </div>
            </Form>
          )}
        </div>
      </Modal>
    </>
  );
}
