import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface SiderBarState {
  selectedkey: "HOME" | "TREES" | "TREE_DETAIL";
  isChangedLanguage: boolean;
}
const initialState: SiderBarState = {
  selectedkey: "HOME",
  isChangedLanguage: false,
};

const hanldeSetSelectedKey = (
  state: SiderBarState,
  action: PayloadAction<"HOME" | "TREES" | "TREE_DETAIL">
) => {
  state.selectedkey = action.payload;
};

const handleChangeLanguage = (
  state: SiderBarState,
  action: PayloadAction<boolean>
) => {
  state.isChangedLanguage = action.payload;
};

export const LayoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSelectedKey: hanldeSetSelectedKey,
    setIsChangedLanguage: handleChangeLanguage,
  },
});

export const { setSelectedKey, setIsChangedLanguage } = LayoutSlice.actions;

export const selectLayout = (state: RootState) => state.layout;

export default LayoutSlice.reducer;
